import { throttle } from 'underscore';
import { useEffect, useRef, useState } from 'react';
import { fetchAPI } from 'lib/connect';
import InputWrapper from 'components/globals/input-wrapper';
import { useMediaQuery } from 'react-responsive';
import config from '../../../../app.config';

import PortIcon from '../../../../public/assets/icons/anker/port.svg';
import tailwindConfig from '../../../../tailwind.config';

export default function SearchFormRegion({
  setValue,
  setCountry,
  setRegion,
  setLocationId,
  errors,
  interacted,
  sd,
  country,
  region,
  locationId,
  reInitialize,
  onNext = function setValue() {
  },
  basements = null,
}) {
  const input = useRef(null);
  const [opened, setOpened] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [results, setResults] = useState([]);

  const isScreenMdOrLarger = useMediaQuery({
    query: `(min-width: ${tailwindConfig.theme.screens.md})`,
  });

  const rows = isScreenMdOrLarger ? [1, 2, 3] : [1, 2];
  const countries = () => navigation.filter((i) => i.type === 'country');

  const regions = () => navigation.filter((i) => i.type === 'region' && i.country_id === country?.id);

  const locations = () => navigation.filter((i) => i.type === 'location' && i.region_id === region?.id);

  const currentCountry = () => countries().find((i) => i?.id === country?.id);

  const itemListCol = (what, index) => {
    let x = [];

    if (what === 'countries') x = countries();
    if (what === 'locations') x = locations();
    if (what === 'regions') x = regions();

    const counter = Math.ceil(x.length / 3);
    const start = counter * (index - 1);
    const end = Math.abs(counter * index);

    if (what === 'countries') return x.slice(start, end);
    if (what === 'locations') return x.slice(start, end);
    if (what === 'regions') return x.slice(start, end);

    return [];
  };

  const next = () => {
    setOpened(false);
    setTimeout(() => {
      onNext();
    }, 10);
  };

  const itemActive = (type, item) => {
    switch (type) {
      case 'country':
        return item.id === country?.id;
      case 'region':
        return item.id === region?.id;
      case 'locaton':
        return item.id === locationId;
      default:
        return false;
    }
  };

  const setItem = (type, item) => {
    setValue(item.name || '');
    input.current.value = item?.name !== undefined ? item.name : '';

    if (type === 'country') {
      setCountry(item);
    }
    if (type === 'region') {
      setCountry(countries().find((i) => i.id === item.country_id));
      setRegion(item);
    }
    if (type === 'location') {
      setCountry(countries().find((i) => i.id === item.country_id));
      setRegion(countries().find((i) => i.id === item.region_id));
      setLocationId(item.id);
    }

    if (type === 'region' && item !== '') {
      next();
    }
  };

  const toggle = () => {
    if (!opened) {
      input.current.setSelectionRange(0, sd.length || 0);
    }

    if (window.innerWidth < 800) {
      setOpened(!opened);
    } else {
      setOpened(true);
    }

    interacted?.();
  };

  const hide = () => {
    setOpened(false);
    if (sd === '') {
      setShowSearchResults(false);
    }
  };

  const setSearchResultItem = (i) => {
    setValue(i.name || '');
    setItem(i.type, i);
    setShowSearchResults(false);
    next();
  };

  const setFirstOnEnter = () => {
    setTimeout(() => {
      if (results.length) {
        setSearchResultItem(results[0]);
      }
    }, 50);
  };

  const search = throttle((e) => {
    if (e.key === 'Enter') {
      setFirstOnEnter();
      return;
    }

    const r = {};
    const sd = e.target.value;

    if (sd.length === 0) {
      setShowSearchResults(false);
      setCountry('');
      setRegion('');
      setLocationId('');
      return;
    }

    setValue(sd);
    setShowSearchResults(true);
    if (basements === null) {
      navigation.forEach((item) => {
        const k = `${item.country_id}${item.id}${item.region_id}`;

        item.synonyms.forEach((s) => {
          if (s.toLowerCase().indexOf(sd.toLowerCase()) !== -1) {
            r[k] = item;
          }
        });
      });
    } else {
      navigation.forEach((item) => {
        const k = `${item.country_id}${item.id}${item.region_id}`;
        if (item.name.toLowerCase().indexOf(sd.toLowerCase()) !== -1) {
          r[k] = item;
        }
      });
    }

    setResults(Object.values(r));
  }, 300);

  const getModel = async () => {
    let data = [];
    if (basements === null) {
      const result = await fetchAPI(`/navigation?channel=${config.channel}&geojson=`);
      data = result.data;
    } else {
      basements.forEach((i) => {
        if (i?.region?.region && i?.region) {
          const indexCountry = data.findIndex((t) => t.type === 'country' && t.id === i.region.region.id);
          if (indexCountry === -1) {
            data.push({
              type: 'country',
              id: i.region.region.id,
              name: i.region.region.name,
              slug: i.region.region.slug,
              country_id: i.region.region.id,
              region_id: null,
              location_id: null,
            });
          }
          const indexRegion = data.findIndex((t) => t.type === 'region' && t.id === i.region.id);
          if (indexRegion === -1) {
            data.push({
              type: 'region',
              id: i.region.id,
              name: i.region.name.replace(`, ${i.region.region.name}`, ''),
              country_id: i.region.region.id,
              region_id: i.region.id,
              location_id: null,
            });
          }
          if (data.findIndex((t) => t.id === i.location.id) === -1) {
            data.push({
              type: 'location',
              id: i.location.id,
              name: i.location.name,
              country_id: i.region.region.id,
              region_id: i.region.id,
              location_id: i.location.id,
            });
          }
        }
      });
    }
    // eslint-disable-next-line no-nested-ternary
    data.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));

    setNavigation(data);
  };

  const setInitialValues = () => {
    input.current.value = sd;
    if (country !== null && country !== undefined) {
      const countryObj = countries().find((i) => i.id === Number(country.id));
      if (countryObj !== undefined) {
        setCountry(countryObj);
        input.current.value = countryObj.name;
      }
    }
    if (region !== null && region !== undefined) {
      const regionObj = navigation.find((i) => i.id === Number(region.id));
      if (regionObj !== undefined) {
        setRegion(regionObj);
        input.current.value = regionObj.name;
      }
    }
    if (locationId !== null && locationId !== undefined) {
      const locationObj = navigation.find((i) => i.id === Number(locationId));

      if (locationObj !== undefined) {
        setLocationId(locationObj.id);
        input.current.value = locationObj.name;
      }
    }
  };

  useEffect(() => {
    setInitialValues();
  }, [reInitialize]);

  // eslint-disable-next-line no-nested-ternary
  const transType = (item) => (item.type === 'country' ? 'Land' : (item.type === 'region' ? 'Region' : 'Ort'));

  const resetSearch = () => {
    setShowSearchResults(false);
    setValue('');
  };

  useEffect(async () => {
    await getModel();
  }, []);

  useEffect(() => {
    setInitialValues();
  }, [navigation]);

  return (
    <InputWrapper
      icon={(
        <PortIcon
          className="absolute z-10 w-5 h-6 pointer-events-none fill-action top-3 left-2"
        />
      )}
      errorMessage={errors?.country?.message}
    >
      <div
        className="relative"
      >
        {opened && (
          <div className="transition-all fixed inset-0 z-10 bg-black bg-opacity-0" onClick={hide} />
        )}
        <input
          ref={input}
          defaultValue={`${sd}`}
          autoComplete="off"
          placeholder="Fahrgebiet wählen oder Suchbegriff eingeben"
          onClick={toggle}
          onKeyUp={search}
          className="w-full pl-10 py-3 pr-5 truncate bg-white border-gray-100 border text-left outline-0 ring-0 drop-shadow-none focus:border-primary focus:outline-0 focus:drop-shadow-none focus:ring-0"
          data-cy="input-region"
        />
        <div
          className={`absolute left-0 bg-white shadow rounded-b rounded-tr top-full z-20  ${opened ? '' : 'hidden'} ${isScreenMdOrLarger ? 'w-[700px] ' : 'max-w-full'}`}
        >
          {!showSearchResults ? (
            <div
              className="p-2"
              data-cy="region-select-overlay"
            >
              {locationId && (
                <div>
                  <div className="d-flex align-items-center">
                    <div className="h5 p-1">
                      Gewählter Ort
                    </div>
                  </div>
                  <a
                    className="d-block px-2 py-1 m-1 bg-secondary"
                    onClick={next}
                  >
                    {sd}
                  </a>
                  <a
                    className="inline-block mt-3 px-3 py-1 bg-gray-100 rounded p-1 text-sm"
                    onClick={() => setItem('location', '')}
                  >
                    Zurück zu den Regionen
                  </a>
                  {currentCountry() && (
                    <a
                      className="mt-3 ml-3 px-3 py-1 inline-block text-sm bg-action rounded"
                      onClick={next}
                    >
                      In
                      {' '}
                      {sd}
                      {' '}
                      suchen
                    </a>
                  )}
                </div>
              )}
              {!locationId && country && (
                <div>
                  <div className="d-flex align-items-center">
                    <div className="h5 p-1">
                      Länder, Regionen & Fahrgebiete
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 md:grid-cols-3">
                    {rows.map((i) => (
                      <div key={i}>
                        {itemListCol('regions', i).map((item, k) => (
                          <a
                            key={k}
                            className={`${itemActive('region', item) ? 'bg-action' : 'bg-gray-100'} block px-2 py-1 m-1 rounded`}
                            onClick={() => setItem('region', item)}
                          >
                            {item.name}
                          </a>
                        ))}

                      </div>
                    ))}
                  </div>
                  <a
                    className="inline-block mt-3 px-3 py-1 bg-gray-100 rounded p-1 text-sm"
                    onClick={() => {
                      input.current.value = '';
                      setValue('');
                      setCountry(null);
                      setRegion(null);
                      setLocationId('');
                    }}
                  >
                    Zurück
                  </a>
                  {currentCountry() && (

                    <a
                      className="mt-3 ml-3 px-3 py-1 inline-block text-sm bg-action rounded"
                      onClick={next}
                    >
                      In
                      {' '}
                      {region?.name || country.name}
                      {' '}
                      suchen
                    </a>
                  )}
                </div>
              )}
              {!locationId && !country && (
                <div>
                  <div className="d-flex align-items-center">
                    <div className="h5 p-1">
                      Länder, Regionen & Fahrgebiete
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 md:grid-cols-3">
                    {rows.map((i) => (
                      <div key={i}>
                        {itemListCol('countries', i).map((item) => (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {!config.regionIdsToHide.includes(item.id)
                              && (
                                <a
                                  key={`country-${item.id}`}
                                  className={`${itemActive('country', item) ? 'bg-action' : 'bg-gray-100'} block px-2 py-1 m-1 rounded`}
                                  onClick={() => setItem('country', item)}
                                >
                                  {item.name}
                                </a>
                              )}
                          </>
                        ))}
                      </div>
                    ))}
                  </div>
                  <a
                    className="inline-block mt-3 px-3 py-1 bg-action rounded p-1 text-sm"
                    href="#"
                    onClick={next}
                  >
                    Liste ausblenden
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div
              className="p-2"
              data-cy="region-search-overlay"
            >
              {sd.length < 3 && (
                <div>
                  Bitte geben Sie mindestens 3 Zeichen ein.
                </div>
              )}
              {sd.length >= 3 && results.length > 0 && (
                <div className="grid sm:grid-cols-2 md:grid-cols-3">
                  {results.map((i) => (
                    <div key={i.type + i.id}>
                      <a
                        className="block px-2 py-1 m-1 bg-gray-100 rounded"
                        onClick={() => setSearchResultItem(i)}
                      >
                        {i.name}
                        {' '}
                        <small className="rounded-full text-sm bg-action-50 px-2 ml-2 ">{transType(i)}</small>
                      </a>
                    </div>
                  ))}
                </div>
              )}
              {sd.length >= 3 && !results.length > 0 && (
                <div>
                  <i>keine Ergebnisse gefunden</i>
                </div>
              )}
              <a
                className="inline-block mt-3 px-3 py-1 bg-action rounded p-1 text-sm"
                onClick={resetSearch}
              >
                Suche zurücksetzen
              </a>
            </div>
          )}
        </div>
      </div>
    </InputWrapper>
  );
}
